export default [
  {
    title: "g.nav_explore",
    route: "Explore",
    icon: "document-search.svg",
    iconActive: "document-search-active.svg",
  },
  {
    title: "g.nav_classroom",
    route: "EnrolledCourse",
    icon: "book-open.svg",
    iconActive: "book-open-active.svg",
  },
];
